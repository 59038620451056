<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode" />
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <van-form @submit="onSubmit">
            <!-- <van-field
                v-model="username"
              >
                <template #input>
                  <div class="titles">
                   <span class="titlesName">患者
                    <van-popover
                      v-model="showPopover"
                      theme="dark"
                      trigger="click"
                      :actions="actions"
                    >
                      <template #reference>
                        <span style="color:#a2a2a2"><van-icon name="info"/></span>
                      </template>
                    </van-popover>
                    </span>
                   <span>
                       <span class="information">
                         <span class="PleaseSelect">请选择</span>
                       </span>
                       <span><van-icon name="arrow" class="vanIcon"/></span>
                   </span>
                  </div>
                </template>
              </van-field> -->

            <MyPatient
              :memberId="memberId"
              :selectValue="selectValue"
              @change="userChange"
            />
            <van-field
              v-model="CO"
              :rules="[
                { required: true, message: '请填写' },
                {
                  pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                  message: '请填写数字',
                },
              ]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">CO（心输出量） <i>*</i></span>
                  <span style="display: flex;align-items: center;">
                    <span class="information">
                      <van-cell-group class="vanCell">
                        <van-field v-model="CO" placeholder="请输入" input-align="right"/>
                      </van-cell-group>
                    </span>
                    <div class="unit"><span>L/min</span></div>
                  </span>
                </div>
              </template>
            </van-field>

            <van-field
              v-model="CaO"
              :rules="[
                { required: true, message: '请填写' },
                {
                  pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                  message: '请填写数字',
                },
              ]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">CaO₂（动脉血氧含量）<i>*</i></span>
                  <span style="display: flex;">
                    <span class="information">
                      <van-cell-group class="vanCell">
                        <van-field v-model="CaO" placeholder="请输入" input-align="right"/>
                      </van-cell-group>
                    </span>
                    <div class="unit"><span>mL O₂/dL</span></div>
                  </span>
                </div>
              </template>
            </van-field>
            <van-field
              v-model="CvO"
              :rules="[
                { required: true, message: '请填写' },
                {
                  pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                  message: '请填写数字',
                },
              ]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">CvO₂(静脉血氧含量) <i>*</i></span>
                  <span style="display: flex;align-items: center;">
                    <span class="information">
                      <van-cell-group class="vanCell">
                        <van-field v-model="CvO" placeholder="请输入" input-align="right"/>
                      </van-cell-group>
                    </span>
                    <div class="unit"><span>mL O₂/dL</span></div>
                  </span>
                </div>
              </template>
            </van-field>
            <div
              style="margin: 16px;display: flex;justify-content: center;"
              v-if="compute === true"
            >
              <van-button
                class="formula-template-button"
                block
                type="info"
                native-type="submit"
                style="width:90px"
                >提交</van-button
              >
            </div>
          </van-form>
          <!-- 计算结果 -->
          <!-- <div class="CalculationResultsBox" v-if="Submitted">
              <div class="CalculationResults"><span>计算结果</span></div>
              <div>
                <div class="CalculationDetail">{{ this.isDeficiency }}</div>
              </div>
            </div> -->
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults
        v-if="show"
        :countText="countTexText"
       
      ></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="StandardDescription"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="references"></FormulaReferences>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getFormula,
  getFormulaHuiXian,
  postFormula,
} from "@/service/BodyMassIndex_api"; //postFormula
import MyPatient from "@/components/MyPatient.vue";
import FormulaBanner from "@/components/FormulaBanner.vue";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "../../../components/template1/FormulaReferences.vue";
import router from "@/router";
import CalculationResults from "@/components/CalculationResults";
export default {
  name: "medicalSearch",
  data() {
    return {
      compute: true,
      show: false,
      //   searchValue:null,
      countTextList: [],
      formulaCode: "",
      isDeficiency: "",
      columnCode: "",
      formulaId: "",
      title: "氧消耗 (VO₂)",
      memberId: "1111",
      patientId: null,
      selectValue: "",
    
      showPopover: false,
      StandardDescription: [],
      BodyMassIndex: "",
      references: [],
      guoji: "",
      PotassiumKFormula: "",
      CO: "",
      CvO: "",
      CaO: "",
      countTexText:'',
    };
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaReferences,
    FormulaExplain,
    CalculationResults,
  },
  created() {
    this.getTheFormula();
    this.showRecord();
  },

    watch: {
      CO: {
        immediate: true,
        handler: function() {
          this.show = false;
        },
      },
      CaO: {
        immediate: true,
        handler: function() {
          this.show = false;
        },
      },
      CvO: {
        immediate: true,
        handler: function() {
          this.show = false;
        },
      },
    },

  methods: {
    // 回显数据
    async showRecord() {
      if (
        this.$router.currentRoute.query.location == "YXGS_RECORD_LIST" &&
        this.$router.currentRoute.query.logId
      ) {
        var data = {
          id: this.$router.currentRoute.query.logId,
        };
        const response = await getFormulaHuiXian(data);
        if (response.code === 0) {
          // console.log("回显数据",response.data)

          this.CO = response.data.content.CO.value;
          this.CaO = response.data.content.CaO2.value;
          this.CvO = response.data.content.CvO2.value;
          if (response.data.patientId)
            this.selectValue = {
              id: response.data.patientId,
              name: response.data.patientName,
            };
          this.patientId = response.data.patientId;
          // 计算结果
          this.countTexText = `${response.data.result.value} mL O₂/min`;
          
          this.$nextTick(() => {
            this.show = true;
          });
        } else {
          Toast.fail(response.message);
        }
      }
    },
    // 获取公式
    async getTheFormula() {
      // console.log("路由",router)
      var data = router.currentRoute.path.substring(
        9,
        router.currentRoute.path.Length
      );
      const response = await getFormula(
        this.$router.currentRoute.query.channel,
        data
      );
      if (response.code === 0) {
        this.StandardDescription = response.data.explain; // 存储标准说明
        this.references = response.data.references; // 参考文献
        // console.log("公式数据",response.data.references)
        this.title = response.data.chineseName; //公式名称
        this.formulaId = response.data.id; //公式id
        this.columnCode = response.data.columnCode;
        this.formulaCode = response.data.code;
      } else {
        Toast.fail(response.message);
      }
    },
    async onSubmit() {
      // 公式
      this.PotassiumKFormula = (this.CO * (this.CaO - this.CvO) * 10).toFixed(
        2
      );
    this.countTexText= `${this.PotassiumKFormula} mL O₂/min`;
      //   var han = `10% 氯化钾液(含每日生理需求)：${Number(
      //     this.PotassiumKFormula
      //   ) + 60}  ~  ${Number(this.PotassiumKFormula) + 70} ml`;
    //   this.countTextList = [];
    //   this.countTextList.push(buhan);
      var data = {};
      data = {
        // columnCode: this.columnCode,
        patientId: this.patientId,
        formulaId: this.formulaId,
        formulaCode: this.formulaCode,
        columnCode: "0",
        content: {
          CO: {
            value: this.CO,
            unit: "L/min ",
          },
          CaO2: {
            value: this.CaO,
            unit: "mL O2 /dL",
          },
          CvO2: {
            value: this.CvO,
            unit: "mL O₂/dL",
          },
        },
        result: {
          value: this.PotassiumKFormula,
          unit: "mL O₂/min",
         DisplayResults:this.countTexText
        },
        channel: this.$route.query.channel,
        location: this.$route.query.location,
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
      };
      // console.log("参数",data)
      // 连接口
      const response = await postFormula(data);
      if (response.code === 0) {
        Toast.success("提交成功");
        this.show = true;
      } else {
        Toast.fail(response.message);
        this.show = true;
      }
    },
    userChange(data) {
      this.selectValue = data;
      // console.log("全部数据",this.selectValue)
      this.patientId = this.selectValue.id;
    },
    // 开参考文献链接
    openLink(u) {
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid =
        userAI.indexOf("Android") > -1 || userAI.indexOf("Linux") > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u);
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  background: #f6f6f6;
  padding: 10px 15px;
  height: 100%;
  .StandardDescription {
    margin-top: 10px;
    .top_nav {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil {
      width: 100%;
      padding: 15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody {
    background-color: #fff;
    padding-bottom: 10px;
    .CalculationResultsBox {
      padding: 0 8px;
      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName {
      font-size: 16px;
      i {
        color: red;
      }
    }
    .information {
      font-size: 16px;
      margin-right: 4px;
      .PleaseSelect {
        color: #b9b9b9;
      }
      .vanCell {
        width: 50px;
        padding: 0;
        height: 100%;
      
       
      }
    }
    .vBotton {
      width: 60px;
    }
    .unit {
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      justify-content:right;
      font-size: 16px;
      width: 80px;
    }
    .vanIcon {
      font-size: 13px;
      color: #a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding: 0px 0px;
}
::v-deep .van-field__control {
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2 {
  width: 100%;
  padding: 15px 10px;
  padding-bottom: 1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references {
    margin-bottom: 15px;
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
</style>
